import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { getExhibitorById, getExhibitorCompanyById, updateExhibitorCompanyById } from "services/exhibitor";
// import { useSelector, useDispatch } from 'react-redux';
// import { RootState } from '../../store';
import { toast } from "react-toastify";


interface ExhibitorFormData {
    name: string;
    first_name: string;
    last_name: string;
    primary_email: string;
    phone: string;
    description: string;
    product_service_keywords: string;
    website_social_media: string;
    logo: File | null; // To handle file input for the logo
}


const PageExhibitorInformation: React.FC = () => {

    const userId = localStorage.getItem('user') || '';
    // const userData = useSelector((state: RootState) => state.user);

    const [isEditable, setIsEditable] = useState(false);
    const [formData, setFormData] = useState<ExhibitorFormData>({
        name: "Company XYZ",
        first_name: "John Doe",
        last_name: "Doe",
        primary_email: "johndoe@example.com",
        phone: "123-456-7890",
        description: "A leading company in event solutions.",
        product_service_keywords: "event, technology, solutions",
        website_social_media: "https://companyxyz.com",
        logo: null,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleEditToggle = () => {
        setIsEditable(!isEditable);
    };

    const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            setFormData({ ...formData, logo: file });
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            // Create a FormData object for the request
            const formDataToSubmit = new FormData();
            formDataToSubmit.append("name", formData.name);
            formDataToSubmit.append("first_name", formData.first_name);
            formDataToSubmit.append("last_name", formData.last_name);
            formDataToSubmit.append("primary_email", formData.primary_email);
            formDataToSubmit.append("phone", formData.phone);
            formDataToSubmit.append("description", formData.description);
            formDataToSubmit.append("product_service_keywords", formData.product_service_keywords);
            formDataToSubmit.append("website_social_media", formData.website_social_media);

            // Append logo file if it exists
            if (formData.logo) {
                formDataToSubmit.append("logo", formData.logo);
            }

            // Make API call to update exhibitor information
            const response = await updateExhibitorCompanyById(userId, formDataToSubmit);

            if (response) {
                toast.success("Exhibitor information updated successfully!");
            } else {
                toast.error(`Failed to update information`);
            }
        } catch (error) {
            toast.error(`Error updating exhibitor information: ${(error as Error).message}`)

        }

    };

    const handleFieldChange = (field: string) => {
        if (!isEditable) {
            alert("Please contact your Event Representative to update these details.");
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await getExhibitorCompanyById(userId);
            if (res) {
                setFormData(res.company);
            }
        }

        fetchData();
    }, [])

    return (
        <div className="container min-h-screen bg-gray-100 p-5">
            <Helmet>
                <title>Exhibitor Information</title>
            </Helmet>
            <div className="bg-white shadow-lg rounded-lg p-6">
                <header className="text-center mb-6">
                    <h1 className="text-2xl font-semibold text-gray-700">Exhibitor Information</h1>
                    <p className="text-gray-500">View and update your exhibitor information.</p>
                </header>

                <form onSubmit={handleSubmit}>
                    <div className="space-y-4">

                        {/* Company Information */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Company Name</label>
                                <input
                                    type="text"
                                    value={formData.name}
                                    disabled
                                    className="mt-1 p-2 w-full bg-gray-100 border border-gray-300 rounded-md"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Contact Name</label>
                                <input
                                    type="text"
                                    value={`${formData.first_name} ${formData.last_name}`}
                                    disabled
                                    className="mt-1 p-2 w-full bg-gray-100 border border-gray-300 rounded-md"
                                />
                            </div>
                        </div>

                        {/* Editable Fields */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Email (Event Guide)</label>
                                <input
                                    type="email"
                                    name="primary_email"
                                    value={formData.primary_email}
                                    onChange={handleChange}
                                    disabled={true}
                                    className="mt-1 p-2 w-full bg-gray-100 border border-gray-300 rounded-md"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Phone (Event Guide)</label>
                                <input
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                    className="mt-1 p-2 w-full bg-white border border-gray-300 rounded-md"
                                />
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Company Description</label>
                            <textarea
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                disabled={!isEditable}
                                className="mt-1 p-2 w-full bg-white border border-gray-300 rounded-md"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Product Keywords</label>
                            <input
                                type="text"
                                name="product_service_keywords"
                                value={formData.product_service_keywords}
                                onChange={handleChange}
                                disabled={!isEditable}
                                className="mt-1 p-2 w-full bg-white border border-gray-300 rounded-md"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">URL</label>
                            <input
                                type="text"
                                name="website_social_media"
                                value={formData.website_social_media}
                                onChange={handleChange}
                                disabled={!isEditable}
                                className="mt-1 p-2 w-full bg-white border border-gray-300 rounded-md"
                            />
                        </div>

                        {/* Logo Upload */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Logo</label>
                            {/* Display the logo */}
                            <div className="flex items-end space-x-4 mt-2">
                                <div className="mt-2">
                                    {formData.logo ? (
                                        typeof formData.logo === 'string' ? (
                                            <img
                                                src={`${process.env.REACT_APP_BACKEND_URL}/${formData.logo}`} // If logo is a URL string, use it directly
                                                alt="Company Logo"
                                                className="h-24 w-24 object-contain rounded-md border"
                                            />
                                        ) : (
                                            <img
                                                src={URL.createObjectURL(formData.logo)} // If it's a file, create a preview URL
                                                alt="Company Logo"
                                                className="h-24 w-24 object-contain rounded-md border"
                                            />
                                        )
                                    ) : (
                                        <div className="h-24 w-24 flex items-center justify-center bg-gray-200 rounded-md border">
                                            <span className="text-gray-500">No Logo</span>
                                        </div>
                                    )}
                                </div>
                                <input
                                    type="file"
                                    onChange={handleLogoChange}
                                    disabled={!isEditable}
                                    className="mt-1 p-2 bg-white border border-gray-300 rounded-md"
                                />
                            </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="mt-6 flex justify-between">
                            <button
                                type="button"
                                onClick={handleEditToggle}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md"
                            >
                                {isEditable ? "Cancel" : "Edit Information"}
                            </button>

                            <button
                                type="submit"
                                className="px-4 py-2 bg-green-500 text-white rounded-md"
                                disabled={!isEditable}
                            >
                                Save Changes/Confirm
                            </button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageExhibitorInformation;
