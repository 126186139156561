import React, { useState, useEffect } from 'react';
import Prices from "components/Prices";
import { PRODUCTS } from "data/data";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Helmet } from "react-helmet-async";
// import CommonLayout from "./CommonLayout";
import { getOrders } from "services/customer/order";
import { formatDate } from 'utils/others';

const PageOrderHistory = () => {

    const [orders, setOrders] = useState<any>([]);

    const renderProductItem = (order: any, index: number) => {
        const {quantity, product} = order;
        const { image_url, name, price } = product;
        return (
            <div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
                <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                    <img
                        src={`${process.env.REACT_APP_BACKEND_URL}/${image_url}`}
                        alt={name}
                        className="h-full w-full object-cover object-center"
                    />
                </div>

                <div className="ml-4 flex flex-1 flex-col">
                    <div>
                        <div className="flex justify-between ">
                            <div>
                                <h3 className="text-base font-medium line-clamp-1">{name}</h3>
                                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                                    <span>{"Natural"}</span>
                                    <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                                    <span>{"XL"}</span>
                                </p>
                            </div>
                            <Prices className="mt-0.5 ml-2" price={ parseFloat(price)}/>
                        </div>
                    </div>
                    <div className="flex flex-1 items-end justify-between text-sm">
                        <p className="text-gray-500 dark:text-slate-400 flex items-center">
                            <span className="hidden sm:inline-block">Qty</span>
                            <span className="inline-block sm:hidden">x</span>
                            <span className="ml-2">{quantity}</span>
                        </p>

                        {/* <div className="flex">
                            <button
                                type="button"
                                className="font-medium text-indigo-600 dark:text-primary-500 "
                            >
                                Details
                            </button>
                        </div> */}

                    </div>
                </div>
            </div>
        );
    };

    const renderOrder = (order: any) => {
        return (
            <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0" key={order?.id}>
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
                    <div>
                        <p className="text-lg font-semibold">#{order?.id}</p>
                        <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
                            <span>{formatDate(order?.created_at)}</span>
                            <span className="mx-2">·</span>
                            <span className="text-primary-500">{ order.status?.toUpperCase() }</span>
                        </p>
                    </div>
                    <div className="mt-3 sm:mt-0">
                        <ButtonSecondary
                            sizeClass="py-2.5 px-4 sm:px-6"
                            fontSize="text-sm font-medium"
                        >
                            View Order
                        </ButtonSecondary>
                    </div>
                </div>
                <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
                    { order?.orderItems?.map(renderProductItem)}
                </div>
            </div>
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await getOrders();
            if (res) {
                setOrders(res);
            }
        }

        fetchData();

    }, []);

    return (
        <div className="nc-CartPage">
            <main className="container py-16 lg:pb-28 lg:pt-20 ">

                <Helmet>
                    <title>Order Review || TorchEvents</title>
                </Helmet>
                <div>
                    {/* <CommonLayout> */}
                    <div className="space-y-10 sm:space-y-12">
                        {/* HEADING */}
                        <h2 className="text-2xl sm:text-3xl font-semibold">Order History</h2>
                        {orders?.map(((order: any)=> renderOrder(order)))}
                    </div>
                    {/* </CommonLayout> */}
                </div>
            </main>
        </div>
    );
};

export default PageOrderHistory;
