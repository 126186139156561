import React, { useState, useEffect } from 'react';
import { getProducts } from 'services/product';
import { createOrder } from 'services/customer/order';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

interface OrderProductProp {
    category_id: number;
}

interface FurnitureItem {
    id: number;
    name: string;
    image_url: string;
    price: number;
    stock: number;
}

const OrderProduct: React.FC<OrderProductProp> = ({ category_id = 1 }) => {

    const navigate = useNavigate();
    // Sample furniture data
    const [furnitureItems, setFurnitureItems] = useState<FurnitureItem[]>([
        {
            id: 1,
            name: 'Office Chair',
            image_url: '/images/furniture-default.jpg',
            price: 100,
            stock: 10,
        },
        {
            id: 2,
            name: 'Desk',
            image_url: '/images/furniture-default.jpg',
            price: 150,
            stock: 5,
        },
        {
            id: 3,
            name: 'Bookshelf',
            image_url: '/images/furniture-default.jpg',
            price: 80,
            stock: 8,
        },
    ]);

    const [order, setOrder] = useState<{ [key: number]: number }>({});
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [product, setProduct] = useState<any>({})

    // Handle change in quantity for furniture item
    const handleQuantityChange = (itemId: number, quantity: number) => {
        setOrder((prevOrder) => ({
            ...prevOrder,
            [itemId]: quantity,
        }));
    };

    // Calculate total price for each item
    const calculateTotalPrice = (item: FurnitureItem) => {
        const quantity = order[item.id] || 0;
        return (item.price * quantity).toFixed(2);
    };

    // Open image modal
    const openImageModal = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setIsImageModalOpen(true);
    };

    // Close image modal
    const closeImageModal = () => {
        setIsImageModalOpen(false);
        setSelectedImage('');
    };

    // Handle confirm order (add to basket)
    const handleConfirmOrder = async () => {
        // alert('Furniture order confirmed. Proceeding to the next step.');

        console.log('order', order);
        const res = await createOrder(order);
        if (res) {
            navigate('/order-basket');
        } else {
            toast.error('There was an error confirming your order.')
        }
    };

    useEffect(() => {

        const fetchData = async () => {
            const res = await getProducts(category_id);
            if (res) {
                setFurnitureItems(res);
            }
        }

        fetchData();
    }, [])

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-4">{`Order ${'Now'}`}</h1>

            {/* Furniture Items List */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {furnitureItems.map((item) => (
                    <div key={item.id} className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-xl font-semibold mb-2">{item.name}</h2>
                        <div className="relative">
                            <img
                                src={`${process.env.REACT_APP_BACKEND_URL}/${item.image_url}`}
                                alt={item.name}
                                className="w-full h-48 object-contain cursor-pointer"
                                onClick={() => {
                                    setProduct(item);
                                    openImageModal(`${process.env.REACT_APP_BACKEND_URL}/${item.image_url}`)
                                }}
                            />
                        </div>
                        <p className="mt-2 text-lg font-semibold">${item.price} per unit</p>
                        <p className="text-sm text-gray-500">Stock: {item.stock} units available</p>

                        {/* Quantity Input */}
                        <div className="mt-4 flex justify-between items-center space-x-4">
                            {/* Quantity Input */}
                            <div className="flex items-center space-x-2">
                                <label
                                    htmlFor={`quantity-${item.id}`}
                                    className="text-sm font-semibold text-gray-700"
                                >
                                    Quantity:
                                </label>
                                <input
                                    id={`quantity-${item.id}`}
                                    type="number"
                                    min="0"
                                    max={item.stock}
                                    value={order[item.id] || 0}
                                    onChange={(e) =>
                                        handleQuantityChange(item.id, parseInt(e.target.value))
                                    }
                                    className="w-20 p-2 border border-gray-300 rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none text-right"
                                />
                            </div>

                            {/* Total Price */}
                            <div className="text-lg font-semibold text-gray-900">
                                Total: <span className="text-blue-600">${calculateTotalPrice(item)}</span>
                            </div>
                        </div>

                    </div>
                ))}
            </div>

            {/* Confirm Order Button */}
            <div className="mt-6">
                <button
                    onClick={handleConfirmOrder}
                    className="bg-green-500 text-white px-6 py-3 rounded hover:bg-green-700"
                >
                    Add to basket
                </button>
            </div>

            {/* Image Modal */}
            {isImageModalOpen && (
                <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 p-5 z-50">
                    <div className="bg-white p-6 rounded-lg relative">
                        <button
                            onClick={closeImageModal}
                            className="absolute top-2 right-2 text-red-600 text-xl font-bold"
                        >
                            X
                        </button>
                        <div className="mt-4 text-center mb-5">
                            <h3 className="text-black font-bold">{product?.name}</h3>
                        </div>
                        <img src={selectedImage} alt="Furniture" className="max-w-xl max-h-96 object-contain mx-auto" />
                        <div className="mt-4 text-center">
                            <p className="text-gray-700 text-sm">{product?.description}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderProduct;
