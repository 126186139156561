import React, { useState, useEffect } from 'react';

interface ExhibitorDelegateData {
  included: number;
  available: number;
  delegatePassValue: number;
  promoCode: string;
  registrationFormLink: string;
}

const PageExhibitorDelegate = () => {
  // Sample data to represent the state (can be fetched from an API)
  const [exhibitorDelegateData, setExhibitorDelegateData] = useState<ExhibitorDelegateData>({
    included: 5,
    available: 3,
    delegatePassValue: 75, // Price of the delegate pass
    promoCode: 'DEF456',
    registrationFormLink: 'https://ticketing.torch-events.app/event/4/form/30',
  });

  // Function to handle the purchase of extra passes
  const handleBuyExtraPasses = () => {
    // In a real app, you would redirect to a purchase flow or API to process the payment
    // alert(`You are buying extra delegate passes for $${exhibitorDelegateData.delegatePassValue} each.`);
    window.open(exhibitorDelegateData.registrationFormLink, '_blank');

  };

  // Function to handle registration redirection
  const handleRegisterDelegate = () => {
    // window.location.href = exhibitorDelegateData.registrationFormLink;
    window.open(exhibitorDelegateData.registrationFormLink, '_blank');

  };

  // This could be used to fetch exhibitor delegate data from an API
  useEffect(() => {
    // Example fetch request:
    // fetch('/api/exhibitor-delegate-data')
    //   .then(response => response.json())
    //   .then(data => setExhibitorDelegateData(data));
  }, []);

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Exhibitor Delegate Registration </h1>

      {/* Exhibitor Delegate Information */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-2">Exhibitor Delegate Information</h2>
        <p>
          You have <span className="font-bold">{exhibitorDelegateData.included}</span> delegate passes included and <span className="font-bold">{exhibitorDelegateData.available}</span> remaining.
        </p>
        <p>
          Promo Code: <span className="font-bold">{exhibitorDelegateData.promoCode}</span>
        </p>
      </div>

      {/* Register Delegate Link */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-2">Register Your Delegate Passes</h2>
        <p>
          To register your delegates, please follow the link below:
        </p>
        <button
          onClick={handleRegisterDelegate}
          className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
        >
          Register Delegate Passes
        </button>
      </div>

      {/* Buy Extra Delegate Passes */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-2">Buy Extra Delegate Passes</h2>
        <p>
          You can buy extra Delegate Passes for <span className="font-bold">${exhibitorDelegateData.delegatePassValue}</span> each.
        </p>
        <button
          onClick={handleBuyExtraPasses}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          Buy Extra Passes
        </button>
      </div>
      {/* Action button */}
      <div className="bg-white p-6 mb-6">
        <a
          href="/dashboard"
          className="inline-block mt-4 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium px-6 py-3 rounded-lg shadow-md hover:from-blue-600 hover:to-blue-700 hover:shadow-lg transition duration-300 ease-in-out"
        >
          Back to Dashboard
        </a>
      </div>
    </div>
  );
};

export default PageExhibitorDelegate;
