import OrderProduct from 'components/OrderProduct';

const PageAdditionalServices = () => {
    return (
        <div className="container mx-auto p-6">
            <OrderProduct category_id={4} />
        </div>
    );
};

export default PageAdditionalServices;
