import OrderProduct from 'components/OrderProduct';

const PageOrderAV = () => {
    return (
        <div className="container mx-auto p-6">
            <OrderProduct category_id={3} />
        </div>
    );
};

export default PageOrderAV;
