import OrderProduct from 'components/OrderProduct';

const PageOrderFurniture = () => {
    return (
        <div className="container mx-auto p-6">
            <OrderProduct category_id={1} />
        </div>
    );
};

export default PageOrderFurniture;
