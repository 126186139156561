import ButtonPrimary from "shared/Button/ButtonPrimary";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import NcImage from "shared/NcImage/NcImage";
import CardDashboard from "components/CardCategories/CardDashboard";
// import { getAllDashboard } from "services/coach/dashboard";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
// import { Line } from 'react-chartjs-2';
import { getDashboardCustomer } from "services/customer/dashboard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.register(ArcElement, Tooltip, Legend);

interface Company {
  id: number;
  name: string | null;
  description: string | null;
  event_id: number | null;
  logo: string | null;
  product_service_keywords: string | null;
  website_social_media: string | null;
  first_name: string | null;
  last_name: string | null;
  primary_email: string | null;
  phone: string | null;
  country: string | null;
  city: string | null;
  postcode: string | null;
  address: string | null;
  registered_date: string | null;
  createdAt: string;
  updatedAt: string;
}

interface CompanyInfoCardProps {
  company: Company;
}

const CompanyInfoCard: React.FC<CompanyInfoCardProps> = ({ company }) => {
  return (
    <div className="w-full bg-white shadow-md rounded-lg p-6 border border-gray-200">
      <div className="flex items-start gap-x-4">
        {/* Logo or Placeholder */}
        {company.logo ? (
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/${company.logo}`}
            alt="Company Logo"
            className="w-32 h-32 object-contain rounded-md"
          />
        ) : (
          <div className="w-16 h-16 flex items-center justify-center bg-gray-300 rounded-md text-gray-700">
            No Logo
          </div>
        )}

        {/* Company Name */}
        <div>
          <h2 className="text-2xl font-bold text-gray-800">{company.name || 'Unknown Company'}</h2>
          <p className="text-sm text-gray-500">{company.description || 'No description available'}</p>
        </div>
      </div>

      {/* Divider */}
      <hr className="my-4 border-gray-300" />

      {/* Contact Info */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h3 className="text-sm font-medium text-gray-600">Contact Name</h3>
          <p className="text-gray-800">{`${company.first_name || 'N/A'} ${company.last_name || 'N/A'}`}</p>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-600">Email</h3>
          <p className="text-gray-800">{company.primary_email || 'N/A'}</p>
        </div>
        {/* <div>
          <h3 className="text-sm font-medium text-gray-600">Phone</h3>
          <p className="text-gray-800">{company.phone || 'N/A'}</p>
        </div>
        <div>
          <h3 className="text-sm font-medium text-gray-600">Website/Social Media</h3>
          <p className="text-gray-800">{company.website_social_media || 'N/A'}</p>
        </div> */}
      </div>

      {/* Address */}
      {/* <div className="mt-4">
        <h3 className="text-sm font-medium text-gray-600">Address</h3>
        <p className="text-gray-800">
          {company.address || 'N/A'}, {company.city || 'N/A'}, {company.postcode || 'N/A'}, {company.country || 'N/A'}
        </p>
      </div> */}

      {/* Registered Date */}
      {/* <div className="mt-4">
        <h3 className="text-sm font-medium text-gray-600">Registered Date</h3>
        <p className="text-gray-800">
          {company.registered_date
            ? new Date(company.registered_date).toLocaleDateString()
            : 'Not registered'}
        </p>
      </div> */}
    </div>
  );
};


const PageDashboard: React.FC = () => {

  const [data, setData]: any = useState({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const chart_data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Statistics',
      },
    },
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  const line_data = {
    labels,
    datasets: [
      {
        label: 'Classes',
        data: labels.map(() => Math.random() * 100),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Student',
        data: labels.map(() => Math.random() * 100),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const sections = [
    {
      title: "Exhibitor Information",
      description: "View and manage exhibitor details.",
      route: "/exhibitor-information",
      bgColor: "bg-blue-100 hover:bg-blue-200"
    },
    {
      title: "Register Booth Staff",
      description: "Register staff for booth management.",
      route: "/register-booth-staff",
      bgColor: "bg-green-100 hover:bg-green-200"
    },
    {
      title: "Register Exhibitor Delegates",
      description: "Register delegates representing exhibitors.",
      route: "/register-exhibitor-delegates",
      bgColor: "bg-red-100 hover:bg-red-200"
    },
    {
      title: "Order Furniture",
      description: "Order furniture for your booth.",
      route: "/order-furniture",
      bgColor: "bg-yellow-100 hover:bg-yellow-200"
    },
    {
      title: "Order Electrics",
      description: "Request electrical services for your booth.",
      route: "/order-electrics",
      bgColor: "bg-purple-100 hover:bg-purple-200"
    },
    {
      title: "Order Audio Visual",
      description: "Order audio and visual equipment.",
      route: "/order-audio-visual",
      bgColor: "bg-pink-100 hover:bg-pink-200"
    },
    {
      title: "Additional Services",
      description: "Explore other services for your booth.",
      route: "/additional-services",
      bgColor: "bg-blue-200 hover:bg-blue-300"
    },
    {
      title: "Review Order",
      description: "Review and confirm your orders.",
      route: "/order-basket",
      bgColor: "bg-green-200 hover:bg-green-300"
    },
    {
      title: "Hotel/Accommodation",
      description: "Book your accommodation at the event venue hotel.",
      route: "#/hetel-accommodation",
      bgColor: "bg-red-200 hover:bg-red-300"
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDashboardCustomer(); // coach id
        setData(data);
        setLoading(false);
      } catch (err: any) {
        setError(err.message || 'An error occurred');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <div className="nc-Page404">
      <Helmet>
        <title>Dashboard || TorchEvents</title>
      </Helmet>
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
        {/* HEADER */}
        <header className="text-left max-w-2xl space-y-2 mb-5">
          <h2 className="text-3xl xl:text-4xl font-semibold">Dashboard</h2>
        </header>
        <div className="mt-1">
          {data?.company && (
            <div className="flex justify-center bg-gray-50 p-4">
              <CompanyInfoCard company={data?.company} />
            </div>
          )}
          {/* <div className="flex flex-row flex-nowrap gap-x-2 bg-gray-200 p-5">
            <CardDashboard name="EVENTS" desc={'1'} size="large" />
            <CardDashboard name="BOOTHS" desc={'1'} size="large" />
            <CardDashboard name="ORDERS" desc={"1"} size="large" />
            <CardDashboard name="COACHES" desc={"5"} size="large" />
          </div> */}
          <div className="min-h-screen bg-gray-100 p-5">
            <div className="bg-white shadow rounded-lg p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">

                {sections.map((section, index) => (
                  <Link to={section.route} key={index} className="block">
                    <div className={`${section.bgColor} p-4 rounded-lg h-28 flex flex-col justify-start transition`}>
                      <h2 className="text-xl font-semibold text-gray-700">{section.title}</h2>
                      <p className="mt-1">{section.description}</p>
                    </div>
                  </Link>
                ))}

              </div>
            </div>

            {/* <div className="flex flex-row flex-nowrap gap-x-2 bg-gray-200 p-5">
              <Pie data={chart_data} />
              <Line options={options} data={line_data} />
            </div> */}
          </div>

          {/* <div className="pt-8">
            <ButtonPrimary href="/">Return Home Page</ButtonPrimary>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default PageDashboard;
