import React, { useState, useEffect } from 'react';

interface BoothStaffData {
  included: number;
  available: number;
  boothStaffPassValue: number;
  promoCode: string;
  registrationFormLink: string;
}

const PageBoothStaff = () => {
  // Sample data to represent the state (can be fetched from an API)
  const [boothStaffData, setBoothStaffData] = useState<BoothStaffData>({
    included: 3,
    available: 2,
    boothStaffPassValue: 50, // Price of the pass
    promoCode: 'ABC123',
    registrationFormLink: 'https://ticketing.torch-events.app/event/4/form/27',
  });

  // Function to handle the purchase of extra passes
  const handleBuyExtraPasses = () => {
    // In a real app, you would redirect to a purchase flow or API to process the payment
    // alert(`You are buying extra booth staff passes for $${boothStaffData.boothStaffPassValue} each.`);
    window.open(boothStaffData.registrationFormLink, '_blank');
  };

  // Function to handle registration redirection
  const handleRegisterStaff = () => {
    window.open(boothStaffData.registrationFormLink, '_blank');
  };

  // This could be used to fetch booth staff data from an API
  useEffect(() => {
    // Example fetch request:
    // fetch('/api/booth-staff-data')
    //   .then(response => response.json())
    //   .then(data => setBoothStaffData(data));
  }, []);

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Booth Staff Registration</h1>

      {/* Booth Staff Information */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-2">Exhibitor Booth Staff Information</h2>
        <p>
          You have <span className="font-bold">{boothStaffData.included}</span> booth staff passes allocated. Please register your booth staff as per instructions below
        </p>
        <p>
          Promo Code: <span className="font-bold">{boothStaffData.promoCode}</span>
        </p>
      </div>
      {/* Register Booth Staff Link */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-2">Register Your Booth Staff</h2>
        <p>
          To register your booth staff, please follow the link below:
        </p>
        <button
          onClick={handleRegisterStaff}
          className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
        >
          Register Booth Staff
        </button>
      </div>
      {/* Buy Extra Booth Staff Passes */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-2">Buy Extra Booth Staff Passes</h2>
        <p>
          You can buy extra Booth Staff Passes for <span className="font-bold">${boothStaffData.boothStaffPassValue}</span> each.
        </p>
        <button
          onClick={handleBuyExtraPasses}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          Buy Extra Passes
        </button>
      </div>

      {/* Action button */}
      <div className="bg-white p-6 mb-6">
        <a
          href="/dashboard"
          className="inline-block mt-4 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium px-6 py-3 rounded-lg shadow-md hover:from-blue-600 hover:to-blue-700 hover:shadow-lg transition duration-300 ease-in-out"
        >
          Back to Dashboard
        </a>
      </div>

    </div>
  );
};

export default PageBoothStaff;
