import OrderProduct from 'components/OrderProduct';

const PageOrderElectric = () => {
    return (
        <div className="container mx-auto p-6">
            <OrderProduct category_id={2} />
        </div>
    );
};

export default PageOrderElectric;
